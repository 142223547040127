import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Search = () => {
	const history = useHistory();
	const [query, setQuery] = useState('');
	const distancesArr = [
		{ name: '5 mi.', value: 5, selected: false },
		{ name: '10 mi.', value: 10, selected: false },
		{ name: '20 mi.', value: 20, selected: true },
		{ name: '50 mi.', value: 50, selected: false },
		{ name: '100 mi.', value: 100, selected: false },
		{ name: 'Any', value: 9999, selected: false }
	];
	const [distances, setDistances] = useState(distancesArr);
	const [distanceValue, setDistanceValue] = useState(distances.filter(distance => distance.selected)[0].value);

	const renderDistanceValues = distanceArr => {
		return distanceArr.map(distance => {
			return <option selected={distance.selected}>{distance.name}</option>;
		});
	};

	const handleSelectDistance = e => {
		let currentUrlParams = new URLSearchParams(window.location.search);
		const selectedDistanceName = e.target.value;
		const newDistances = [...distances];
		newDistances.map(distance => {
			distance.selected = distance.name === selectedDistanceName;
			return distance;
		});
		setDistances(newDistances);
		const newDistanceValue = newDistances.filter(distance => distance.selected)[0].value;
		currentUrlParams.set('distance', newDistanceValue.toString());
		setDistanceValue(newDistanceValue);
		history.push(`${window.location.pathname}?${currentUrlParams.toString()}`)
	};

	const handleLocationQuery = async query => {
		setQuery(query);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		history.push(`/location?id=${query}&distance=${distanceValue}`);
	};

	return (
		<Fragment>
			<div className='flex-1 flex justify-center'>
				<div className=''>
					<label htmlFor='search' className='sr-only'>
						Search FAANG Engineering Jobs by Location
					</label>
					<div className='inline-flex px-2 lg:px-6'>
						<div className='min-w-full flex relative text-gray-300 focus-within:text-green-500 container'>
							<form onSubmit={e => handleSubmit(e)}>
								<input
									className='h-15 block pl-10 pr-3 rounded-r-none py-2 border-gray-300 border-0 shadow-md focus:shadow-xl
                                    border-r-0 border-solid rounded-l-md leading-5 bg-white text-gray-300 placeholder-gray-300 border-white
                                    focus:outline-none focus:bg-white focus:placeholder-gray-400 focus:text-gray-900 sm:text-xl transition duration-150 ease-in-out'
									placeholder='Search by Location'
									type='search'
									autoComplete={'off'}
									onChange={e => handleLocationQuery(e.target.value)}
								/>
								<span className='absolute inset-y-0 left-0 flex items-center pl-2'>
									<button type='submit' className='p-1 focus:outline-none focus:shadow-outline'>
										<svg fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' viewBox='0 0 24 24' className='w-6 h-6'>
											<path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
										</svg>
									</button>
								</span>
							</form>
							<div className='flex bg-white inline-block inset-y-0 right-0 items-center border-0 border-solid rounded-r-md rounded-l-none border-opacity-75 border-gray-300 pr-2'>
								<label htmlFor='distance' className='sr-only'>
									Distance
								</label>
								<select
									onChange={e => handleSelectDistance(e)}
									id='distance'
									name='distance'
									className='focus:ring-green-500 focus:border-green-500 h-full py-0 pl-3 pr-0 border-transparent bg-transparent text-green-400 sm:text-md font-bold rounded-md m-1'
								>
									{renderDistanceValues(distances)}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Search;
