import React, { Fragment } from 'react';
import Logo from '../../assets/img/logo.svg';
import { cities } from "../../constants"
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { GlobeOutline, MailOpenOutline } from "heroicons-react";
import TWLogo from '../../assets/img/tw-logo-dark.svg';

const Footer = () => {
    const getCurrentYear = () => {
        const d = new Date();
        return d.getFullYear();
    };

    const renderCities: any = (cityArr: any, startIndex: number, endIndex: number) => {
        return cities.slice(startIndex, endIndex).map((city: any, index) => {
            return (
                <Fragment>
                    <li>
                        <Link key={index} to={`/location?id=${city.location}`}
                              className="text-base text-gray-500 hover:text-gray-900">
                            <h3>FAANG Engineering Jobs in {city.name}</h3>
                        </Link>
                    </li>
                    <li>
                        <Link key={index} to={`/location?id=${city.location}`}
                              className="text-base text-gray-500 hover:text-gray-900">
                            <h3>FAANG Developer Jobs in {city.name}</h3>
                        </Link>
                    </li>
                </Fragment>
            );
        });
    };


    return (
        <div className='bg-white border-t border-gray-300'>
            <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                <div className="xl:grid xl:grid-cols-3 xl:gap-8 text-center sm:text-left">
                    <div className="space-y-8 xl:col-span-1">
                        <img className='h-10 mx-auto sm:mx-0' src={Logo} alt='faang.io logo'/>
                        <p className="text-gray-500 text-base text-center sm:text-left">
                            FAANG Engineering jobs in one spot.
                        </p>
                        <div className="flex space-x-6">
                            <a href="https://twitter.com/faangjobs?ref=faang.io-footer"
                               target={"_blank"}
                               className="mx-auto sm:mx-0 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                                </svg>
                            </a>
                        </div>
                        <div>
                            <p className="mb-3 text-gray-500 text-xs font-bold text-center sm:text-left">
                                Have a tool or resource that will<br/> help devs land a FAANG job?
                            </p>
                            <HashLink to={'/promote'}>
                                <button
                                    type='button'
                                    className='relative inline-flex items-center px-4 py-2 border border-transparent text-xs leading-5 font-medium rounded-md text-white bg-indigo-500 hover:opacity-75 shadow-sm focus:outline-none focus:border-indigo-400 focus:shadow-outline-indigo active:bg-indigo-400 transition ease-in-out duration-150'
                                >
                                    <GlobeOutline className='-ml-1 mr-1 h-5 w-5'/>
                                    <span>Promote with Us!</span>
                                </button>
                            </HashLink>
                        </div>
                    </div>
                    <div
                        className="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-8 xl:mt-0 xl:col-span-2 text-center sm:text-left">
                        <div className="md:grid md:grid-cols-1 md:gap-8">
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    FAANG Engineering Jobs
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    {renderCities(cities, 0, cities.length / 2)}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-1 md:gap-8 grid-">
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    FAANG Software Engineer Jobs
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    {renderCities(cities, cities.length / 2, cities.length)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-12 border-t border-gray-300 pt-8 flex flex-col items-center'>
                    <div className="">
                        <p className='text-base leading-6 text-gray-400 xl:text-center'>&copy; {getCurrentYear()} |
                            faang.io | All rights reserved.</p>
                    </div>
                    <a className={'mt-5'} href="https://www.producthunt.com/posts/faang-io?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-faang-io" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=280884&theme=dark" alt="FAANG.io - FAANG Engineering Jobs, In One Spot. | Product Hunt" style={{width: 250, height: 54}} width="250" height="54" /></a>
                    <div className="mt-5">
                        <a target={'_blank'} href={'https://timwheeler.com?ref=faang.io_footer'}>
                            <img
                                alt={'TimWheeler.com Logo'}
                                src={TWLogo}
                                style={{ height: 25, width: 'auto' }}
                                className={'block m-auto'}
                            />
                            <h5 className={'text-xs font-semibold text-gray-400 tracking-wider uppercase'}>Built by Tim
                                Wheeler</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
