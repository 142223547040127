import React, { Fragment, useState } from 'react';
import Tag from './Tag';
import MarkdownIt from 'markdown-it';
import { CursorClickOutline, EyeOutline, ThumbDownOutline, ThumbUpOutline, X } from 'heroicons-react';
import { Link } from 'react-router-dom';
import { BEARER_TOKEN, Vote } from "../constants";

const ListingDetails = ({ listing, closeDetailsCallback, notificationCallback }) => {
	const { _id, company, logo_url, title, description, salary, city, apply_url, tags, views, clicks, created_date } = listing;
	const [isUpvoteDisabled, setIsUpvoteDisabled] = useState(false)
	const [isDownvoteDisabled, setIsDownvoteDisabled] = useState(false)

	const markdown = new MarkdownIt({
		html: true
	});

	const handleApplyClick = async () => {
		const res = await fetch(`/api/listings/${_id}/click`);
		const data = await res.json();
	};

	const handleFeedback = async ({ type: type }) => {
		const res = await fetch(`/api/listings/${_id}/${type.toLowerCase()}`);
		const data = await res.json();
		type === Vote.UPVOTE ? setIsUpvoteDisabled(true): setIsDownvoteDisabled(true);
		notificationCallback(type);
	};

	return (
		<Fragment>
			<div className={'mt-2 p-4'}>
				<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
					<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Company
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{company}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Job Title
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{title}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Location
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{city}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Salary
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{salary || 'Not Provided'}
							</dd>
						</div>
						{tags.length ? (<div className="sm:col-span-2">
							<dt className="text-sm font-medium text-gray-500">
								Tags
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{tags.map((tag, i) => {
									return <Tag key={i}>{tag}</Tag>;
								})}
							</dd>
						</div>) : null}
						<div className="sm:col-span-2">
							<dt className="text-sm font-medium text-gray-500 mb-2">
								Description
							</dt>
							<dd
								id={'job-description-section'}
								dangerouslySetInnerHTML={{ __html: markdown.render(description) }}
								className='leading-loose break-words overflow-y-scroll h-48 mt-2 text-xs leading-5 text-gray-900 sm:mt-0 sm:col-span-3 overflow-visible'
							/>
						</div>
						<div className="sm:col-span-2">
							<dd className="mt-1 text-sm text-gray-900">
								<div className='w-1/2 m-auto block text-center'>
									<a onClick={() => handleApplyClick()} target={'_blank'} href={apply_url}>
									<button
										className="text-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
										Apply
									</button>
									</a>
								</div>
								<div className={'w-full inline-flex flex-row justify-center space-x-4 mt-5'}>
									<button
										disabled={isDownvoteDisabled}
										onClick={() => handleFeedback({ type: Vote.DOWNVOTE })}
										className='job-listing-feedback-button flex-end rounded-full bg-gray-100 active:bg-indigo-100 focus:outline-none focus:shadow-outline-indigo shadow-outline-indigo rounded-full p-1'
										type='button'
									>
										<ThumbDownOutline
											className={'job-listing-feedback-button w-5 h-5 text-gray-500'} />
									</button>
									<button
										disabled={isUpvoteDisabled}
										onClick={() => handleFeedback({ type: Vote.UPVOTE })}
										className='job-listing-feedback-button flex-end rounded-full bg-gray-100 active:bg-indigo-100 focus:outline-none focus:shadow-outline-indigo shadow-outline-indigo rounded-full p-1'
										type='button'
									>
										<ThumbUpOutline
											className={'job-listing-feedback-button w-5 h-5 text-gray-500'} />
									</button>
								</div>
								<div className='job-listing-feedback-button text-center p-3 mt-2'>
									<Link to={`/feedback?id=${listing._id}`}>
										<button type="button"
												className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
											<h6 className={'text-sm text-gray-400'}>Leave
												Additional Feedback</h6>
										</button>
									</Link>
								</div>
								<div className={'flex justify-end space-x-4'}>
									<button
										onClick={e => closeDetailsCallback(e)}
										className='flex-end rounded-full bg-gray-100 active:bg-indigo-100 focus:outline-none focus:shadow-outline-indigo shadow-outline-indigo rounded-full p-1'
										type='button'
									>
										<X className={`w-5 h-5 text-indigo-500 -rotate-90 transform`} />
									</button>
								</div>
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</Fragment>
	);
};

export default ListingDetails;
