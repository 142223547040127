export enum Notifications {
    DISPLAY = 'NOTIFICATIONS_DISPLAY',
    HIDE = 'NOTIFICATION_HIDE'
}

export enum Vote {
    UPVOTE = 'UPVOTE',
    UPVOTED = 'Upvoted',
    DOWNVOTE = 'DOWNVOTE',
    DOWNVOTED = 'Downvoted'
}
