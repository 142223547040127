import React, { Fragment, useEffect } from 'react';
import Hero from '../../components/Hero';
import FeedbackSection from '../../components/FeedbackSection';

const Feedback = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<Hero />
			<FeedbackSection />
		</Fragment>
	);
};

export default Feedback;
