import React, { Fragment } from 'react';
import Hero from '../../components/Hero';
import ListingSection from '../../components/ListingSection';
import { ListingTypes } from "../../constants";

const Home = () => {
	return (
		<Fragment>
			<Hero />
			<ListingSection listingType={ListingTypes.DEFAULT} />
		</Fragment>
	);
};

export default Home;
