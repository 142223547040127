import React, { useEffect, useState } from 'react';
import './assets/css/styles.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import moment from 'moment-timezone';

import AuthContext from './context/AuthContext';
import NotificationContext from './context/NotificationContext';
import PromoContext from './context/PromoContext';
import { CookiesProvider } from 'react-cookie';

import combineReducers from './reducers';
import authReducer from './reducers/authReducer';
import dashboardReducer from './reducers/dashboardReducer';
import notificationReducer from './reducers/notificationReducer';

import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './layout/DashboardLayout';
import DashboardHome from './views/dashboard/DashboardHome';
import AppLayout from './layout/AppLayout';
import Home from './views/landing/Home';
import Login from './views/landing/Login';
import Register from './views/landing/Register';
import Location from './views/landing/Location';
import Company from './views/landing/Company';
import PromoPost from './views/landing/PromoPost';
import promoReducer from './reducers/promoReducer';
import Feedback from './views/landing/Feedback';
import queryReducer from "./reducers/queryReducer";
import QueryContext from "./context/QueryContext";
import { ListingSettings } from "./constants";
import PrivacyPolicy from "./views/landing/PrivacyPolicy";

moment.tz.setDefault('Etc/UTC');

const App = () => {
    const initialState = {
        authenticated: false,
        user: null
    };

    const initialQueryState = {
        query: "",
        distance: ListingSettings.DEFAULT_DISTANCE
    }

    const initalPromoState = {
        limit: 25,
        post: {
            company: '',
            apply_url: '',
            logo_url: '',
            title: '',
            city: '',
            salary: '',
            description: '',
            billing_email: ''
        },
        postPage: 0,
        postValid: false,
        postSteps: [
            {
                complete: true,
                active: false,
                text: 'Company Details'
            },
            {
                complete: false,
                active: true,
                text: 'Promo Details'
            },
            {
                complete: false,
                active: false,
                text: 'Payment Details'
            }
        ],
        location: {
            latitude: null,
            longitude: null,
            error: {
                message: null,
                status: null
            },
            city: null
        }
    };

    const initialDashboardState = {};

    const initialNotificationState = {
        display: false,
        message: null
    };

    const [state, dispatch] = React.useReducer(
        combineReducers({
            auth: authReducer,
            query: queryReducer,
            promo: promoReducer,
            dashboard: dashboardReducer,
            notification: notificationReducer
        }),
        {
            auth: initialState,
            query: initialQueryState,
            promo: initalPromoState,
            dashboard: initialDashboardState,
            notification: initialNotificationState
        }
    );

    const { auth, query, promo, notification } = state;

    return (
        <AuthContext.Provider value={{ state: auth, dispatch }}>
            <CookiesProvider>
                <NotificationContext.Provider value={{ state: notification, dispatch }}>
                    <PromoContext.Provider value={{ state: promo, dispatch }}>
                        <QueryContext.Provider value={{ state: query, dispatch }}>
                            <div>
                                <Router>
                                    <Switch>
                                        <Route exact path={'/dashboard'}>
                                            <DashboardLayout>
                                                <Switch>
                                                    <ProtectedRoute auth={{ user: auth.user }} exact path={'/dashboard'}
                                                                    component={DashboardHome}/>
                                                </Switch>
                                            </DashboardLayout>
                                        </Route>
                                        <Route path={'/'}>
                                            <AppLayout>
                                                <Switch>
                                                    <Route exact path={'/'} component={Home}/>
                                                    <Route exact path={'/location'} component={Location}/>
                                                    <Route exact path={'/feedback'} component={Feedback}/>
                                                    <Route exact path={'/promote'} component={PromoPost}/>
                                                    <Route exact path={'/:company/listings'} component={Company}/>
                                                    <Route exact path={'/privacy-policy'} component={PrivacyPolicy}/>
                                                    {/*<Route path={'/login'} component={Login}/>*/}
                                                    {/*<Route exact path={'/register'} component={Register}/>*/}
                                                </Switch>
                                            </AppLayout>
                                        </Route>
                                    </Switch>
                                </Router>
                            </div>
                        </QueryContext.Provider>
                    </PromoContext.Provider>
                </NotificationContext.Provider>
            </CookiesProvider>
        </AuthContext.Provider>
    );
};

export default App;
