import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from '../../assets/img/logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import AuthContext from '../../context/AuthContext';
import Avatar from '../Avatar';
import { MailOpen, MailOpenOutline, Plus, SpeakerphoneOutline } from 'heroicons-react';

const Header = () => {
	const { state: auth, dispatch } = React.useContext(AuthContext);
	const { user, authenticated } = auth;
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [toggleMenu, setToggleMenu] = useState(false);
	const history = useHistory();
	const [avatar, setAvatar] = useState('/assets/img/avatar.png');
	const [username, setUsername] = useState('');
	const dropDownRef = useRef();

	useEffect(() => {
		if (user?.avatar) {
			setAvatar(user.avatar);
		}
	}, [user]);

	useEffect(() => {
		const handleClickEvent = event => {
			// @ts-ignore
			if (dropDownRef.current && !event.target.classList.contains('dashboard-dropdown')) {
				setToggleDropdown(false);
			}
		};
		document.addEventListener('mousedown', handleClickEvent);
		return () => {
			document.removeEventListener('mousedown', handleClickEvent);
		};
	}, [dropDownRef]);

	const handleLogout = async () => {
		const res = await fetch('/auth/logout');
		const data = await res;
		dispatch({ type: 'USER_LOGOUT' });
		history.push('/');
	};

	return (
		<nav className='bg-white shadow relative'>
			<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
				<div className='flex justify-between h-16'>
					<div className='flex'>
						<div className='flex-shrink-0 flex items-center'>
							<Link to={'/'}>
								<img className='block lg:hidden h-8 w-auto' src={Logo} alt='faang.io logo' />
								<img className='hidden lg:block h-8 w-auto' src={Logo} alt='faang.io logo' />
							</Link>
						</div>
					</div>
					<div className='flex items-center'>
						<div className='flex-shrink-0'>
							<Link to={'/feedback'} className={'text-gray-500 text-sm pr-5 relative inline-flex items-center px-4 py-2'}>
								<SpeakerphoneOutline className='-ml-1 mr-2 h-5 w-5' />
								<span className={'hidden sm:block'}>Leave Feedback</span>
							</Link>
						</div>
						<div className='flex-shrink-0'>
							<HashLink to={'/#subscribe'}>
								<button
									type='button'
									className='relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-400 hover:opacity-75 shadow-sm focus:outline-none focus:border-green-400 focus:shadow-outline-green active:bg-green-400 transition ease-in-out duration-150'
								>
									<MailOpenOutline className='-ml-1 mr-1 h-5 w-5' />
									<span>Subscribe</span>
								</button>
							</HashLink>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Header;
