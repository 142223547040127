export const companies = [
    { display: 'Facebook', name: 'facebook' },
    { display: 'Amazon', name: 'amazon' },
    { display: 'Apple', name: 'apple' },
    { display: 'Netflix', name: 'netflix' },
    { display: 'Google', name: 'google' }
];

export enum Companies {
    FACEBOOK = 'Facebook',
    AMAZON = 'Amazon',
    APPLE = 'Apple',
    NETFLIX = 'Netflix',
    GOOGLE = 'Google'
}
