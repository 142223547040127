import React, { Fragment } from 'react';
import Hero from '../../components/Hero';
import ListingSection from '../../components/ListingSection';
import { ListingTypes } from "../../constants";

const Location = () => {
	return (
		<Fragment>
			<Hero />
			<ListingSection listingType={ListingTypes.LOCATION} />
		</Fragment>
	);
};

export default Location;
