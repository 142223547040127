import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import InfoBar from './InfoBar';
import { SpeakerphoneOutline } from 'heroicons-react';
import FaangMountains from '../assets/img/faang-mountains.svg';
import { BEARER_TOKEN } from '../constants';

const FeedbackSection = () => {
	const FORM_ENDPOINT = 'https://sendpoint.io/id/faang';
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query: any = useQuery();
	const listingId = query.get('id');
	const [listing, setListing] = useState(null);

	const fetchListing = async (listingId) => {
		const res = await fetch(`/api/listings/${listingId}`);
		const data = await res.json();
		const { listing } = data;
		setListing(listing);
	};

	useEffect(() => {
		fetchListing(listingId).catch(e => e);
	}, [listingId]);

	return (
		<Fragment>
			<div>
				<div className="inset-0">
					<div className="inset-y-0 left-0 w-1/2 bg-gray-100" />
				</div>
				<div className="relative mx-auto lg:grid lg:grid-cols-5">
					<div style={{ background: `url(${FaangMountains})`, backgroundSize: 'cover', backgroundRepeat: 'none'}} className="bg-gray-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
						<div className="max-w-lg mx-auto">
							<h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">
								Leave Us Feedback
							</h2>
							<p className="mt-3 text-lg leading-6 text-white">
								Our goal is to curate FAANG Software Engineering jobs in one convenient spot.
							</p>
							<p className="mt-3 text-lg leading-6 text-white">
								Have some thoughts on how we could do better? Leave us some feedback, but be nice!
							</p>
							<dl className="mt-8 text-base text-white">
								<div className="mt-3">
									<dt className="sr-only">Email</dt>
									<dd className="flex">
										<svg className="flex-shrink-0 h-6 w-6 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
											 stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
												  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
										</svg>
										<span className="ml-3 font-bold text-white">faang@timwheeler.com</span>
									</dd>
								</div>
							</dl>
						</div>
					</div>
					<div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
						<div className="max-w-lg mx-auto lg:max-w-none">
							{listingId && listing ? (
								<Fragment>
									<InfoBar content={`${listing?.company} • ${listing?.title} • ${listing?.city}`}/>
								</Fragment>
							) : null}
							<form action={FORM_ENDPOINT} method="POST" className="grid grid-cols-1 gap-y-6 pt-4">
								<div>
									<label htmlFor="name" className="sr-only">Name</label>
									<input type="text" name="name" id="name" autoComplete="name"
										   className="border border-gray-300 rounded-md block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-green-400 focus:border-green-500"
										   placeholder="Name (not required)" />
								</div>
								<div>
									<label htmlFor="email" className="sr-only">Email</label>
									<input id="email" name="email" type="email" autoComplete="email"
										   className="border border-gray-300 rounded-md block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-green-400 focus:border-green-500"
										   placeholder="Email (not required)" />
								</div>
								<div>
									<label htmlFor="message" className="sr-only">Message*</label>
									<textarea
										required
										id="message" name="message" rows={4}
										className="border border-gray-300 rounded-md block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-green-400 focus:border-green-500"
										placeholder="Message*" />
								</div>
								{listingId ? (
									<div>
										<input
											value={listingId}
											type="hidden" name="ID" id="id"
										/>
										<input
											value={listing?.company}
											type="hidden" name="Company" id="company"
										/>
										<input
											value={listing?.title}
											type="hidden" name="Job Title" id="title"
										/>
										<input
											value={listing?.city}
											type="hidden" name="City" id="city"
										/>
									</div>
								) : null}
								<div className="g-recaptcha" data-sitekey="6Lc1aCoaAAAAALf3xQEyeCgVki11c7-0itZXT6fl" />
								<div>
									<button type="submit"
											className="inline-flex items-center pl-3 pr-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
										<SpeakerphoneOutline className={'mr-3'}/>
										Leave Feedback
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default FeedbackSection;
