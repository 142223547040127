import React, { Fragment } from 'react';
import { BriefcaseOutline } from 'heroicons-react';

const InfoBar = ({ content, color = 'green' }) => {
	return (
		<Fragment>
			<div className={`rounded-md bg-${color}-200 p-4`}>
				<div className="flex">
					<div className="flex-shrink-0">
						<BriefcaseOutline className={`h-5 w-5 text-${color}-500`}/>
					</div>
					<div className="ml-3 w-full">
						<p className={`text-sm font-medium text-${color}-800`}>
							{ content }
						</p>
					</div>
				</div>
			</div>
		</Fragment>
	)
};

export default InfoBar;
