const promoReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'USER_LOCATION':
			return {
				...state,
				location: action.payload
			};
		case 'PROMO_POST':
			return {
				...state,
				post: action.payload
			};
		case 'PROMO_POST_PAGE':
			const updatedPromoSteps = [...state.updatedPromoSteps];
			// update new page step
			updatedPromoSteps[action.payload].active = true;
			// update previous page step
			updatedPromoSteps[state.postPage].active = false;
			updatedPromoSteps[state.postPage].complete = true;
			return {
				...state,
				postPage: action.payload,
				postSteps: updatedPromoSteps
			};
		case 'PROMO_POST_VALID':
			return {
				...state,
				postValid: action.payload
			};
		default:
			return state;
	}
};

export default promoReducer;
