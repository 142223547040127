import React, { Fragment, useContext, useState } from 'react';
import Tag from './Tag';
import ListingDetails from './ListingDetails';
import { Link } from 'react-router-dom';
import {
	ChevronLeft,
	ClockOutline,
	CursorClickOutline, ExternalLink,
	EyeOutline,
	ThumbDownOutline,
	ThumbUpOutline
} from 'heroicons-react';
import moment from 'moment';
import DefaultCompanyLogo from '../assets/img/company.jpg';
import { IListing } from './types';
import { BEARER_TOKEN, Metric, Vote } from '../constants';

const Listing = (listing: IListing) => {
	const [toggleDetails, setToggleDetails] = useState(false);
	const {
		_id,
		company,
		logo_url,
		title,
		city,
		salary,
		description,
		posted_date,
		apply_url,
		company_tag,
		tags,
		clicks,
		views,
		billing_email,
		is_promo,
		is_enabled,
		callback
	} = listing;

	const [isUpvoteDisabled, setIsUpvoteDisabled] = useState(false);
	const [isDownvoteDisabled, setIsDownvoteDisabled] = useState(false);

	const posted_days_ago = moment().diff(posted_date, 'days');

	const handleDefaultImage = e => {
		e.target.src = DefaultCompanyLogo;
	};

	const handleLogoUrl = (company?: string) => {
		if (is_promo) return logo_url;
		return `/assets/img/icons/${company.toLowerCase()}.svg`;
	};

	const handlePromoClick = async () => {
		const res = await fetch(`/api/listings/${_id}/click`);
		const data = await res.json();
	};

	const toggleDetailSection = async (e) => {
		/*
			TODO: Using target/currentTarget is a bug-prone approach. Refactor this in the future
		*/
		if (is_promo) return;
		const jobListingTarget = e.currentTarget.classList.contains('job-listing');
		const jobListingFeedbackTarget = e.target.classList.contains('job-listing-feedback-button');
		if (jobListingFeedbackTarget || (jobListingTarget && toggleDetails)) return;
		if (jobListingTarget && !toggleDetails) {
			await fetch(`/api/listings/${_id}/view`);
		}
		setToggleDetails(!toggleDetails);
	};

	const generatePostedDaysAgoText = () => {
		if (posted_days_ago === 0) {
			return 'today';
		}
		return posted_days_ago === 1 ? `yesterday` : `${posted_days_ago}d`;
	};

	const handleFeedback = async ({ voteType: type }) => {
		const res = await fetch(`/api/listings/${_id}/${type}`);
		const data = await res.json();
		type === Vote.UPVOTE ? setIsUpvoteDisabled(true) : setIsDownvoteDisabled(true);
		callback(type);
	};

	return (
		<li onClick={e => toggleDetailSection(e)}
			className={`${is_promo ? 'border-indigo-500 border-4' : 'border-grey-200 border'} job-listing mb-5 shadow rounded-md relative pl-3 pr-3 pt-3 pb-3 hover:bg-gray-50 sm:pt-3 sm:pb-3 sm:pl-3 lg:pl-3 xl:pl-3`}
		>
			<div className='items-center justify-between space-x-4 z-10'>
				<div className='flex'>
					<div className={!is_promo ? `w-full lg:w-3/4` : `lg:w-3/4`}>
						{/* Company Logo, Name, Job Title */}
						<div className='inline-flex items-center space-x-3 w-5/6 self-center'>
							<div className='m-h-full m-w-full p-1 rounded shadow '>
								<img
									onError={e => handleDefaultImage(e)}
									className={'inline-block object-contain'}
									style={{ height: 40, width: 40, minHeight: 40, minWidth: 40 }}
									src={handleLogoUrl(company)}
									alt={`logo`}
								/>
							</div>
							<span className='inline-block'>
								<h2 className='flex items-start text-sm font-bold leading-5'>
									<span className={''}>{title}</span>
								</h2>

								<div
									className='text-sm mt-1 leading-5 text-gray-500 group-hover:text-gray-900 font-medium'>
									{ !is_promo ? (
										<Fragment>
										<Link to={is_promo ? apply_url.split('?')[0] : `/${company_tag}/listings`}>
											<span>{company}</span>
										</Link>{' '}
										/{' '}
										<Link to={is_promo ? apply_url.split('?')[0] : `/location?id=${city}`}>
											<span className={'font-bold'}>{is_promo ? apply_url.split('?')[0] : city}</span>
										</Link>
										</Fragment>
									): (
										<Fragment>
											<a onClick={handlePromoClick} href={apply_url.split('?')[0]} target={'_blank'}>
												<span>{company}</span>
											</a>{' '}
											/{' '}
											<a onClick={handlePromoClick} href={apply_url.split('?')[0]} target={'_blank'}>
												<span className={'font-bold'}>{apply_url.split('?')[0]}</span>
											</a>
										</Fragment>
									)}
								</div>
							</span>
						</div>
						{/* Mobile Toggle Button */}
						{!is_promo ? (<div className='inline-flex lg:hidden justify-end align-middle w-1/6'>
							<button
								onClick={e => toggleDetailSection(e)}
								className={
									'z-10 job-listing-details-toggle-button bg-gray-100 active:bg-gray-200 focus:outline-none focus:shadow-outline-gray shadow-outline-gray rounded-full flex justify-end p-1'
								}
							>
								<ChevronLeft
									className={`${toggleDetails ? '-rotate-90 transform' : ''} w-5 h-5 text-gray-500 text-center m-auto block`} />
							</button>
						</div>) : null}
					</div>
					{!is_promo ? (<div className='w-full lg:w-1/2 hidden lg:block self-center'>
						<div className='flex justify-end items-center space-x-4'>
							<div className={'hidden md:flex justify-end pt-1'}>
								<div className={'hidden md:flex inline-block whitespace-no-wrap'}>
									<EyeOutline className={'inline-block text-gray-400 h-4 w-4'}>{views}</EyeOutline>
									<div className={'inline-block ml-1 text-xs text-gray-400'}>
										{views || 0} {views === 1 ? Metric.VIEW : Metric.VIEWS}
									</div>
								</div>
								<div className={'hidden md:flex ml-3 inline-block whitespace-no-wrap'}>
									<CursorClickOutline
										className={'inline-block text-gray-400 h-4 w-4'}>{clicks}</CursorClickOutline>
									<div className={'inline-block ml-1 text-xs text-gray-400'}>
										{clicks || 0} {clicks === 1 ? Metric.CLICK : Metric.CLICKS}
									</div>
								</div>
							</div>
							<div className={'flex justify-end inline-block ml-1 text-xs text-gray-400'}>
								<Tag color={'blue'}>
									<ClockOutline className={'w-3 h-3 mr-1'} /> {generatePostedDaysAgoText()}
								</Tag>
							</div>
							<button
								disabled={isDownvoteDisabled}
								onClick={() => handleFeedback({ voteType: Vote.DOWNVOTE })}
								className='z-10 job-listing-feedback-button hidden md:flex bg-gray-100 active:bg-gray-100 disabled:bg-gray-100 disabled:opacity-25 focus:outline-none focus:shadow-outline-gray shadow-outline-gray rounded-full flex justify-end p-1'
								type='button'
							>
								<ThumbDownOutline className={'job-listing-feedback-button w-5 h-5 text-gray-500'} />
							</button>
							<button
								disabled={isUpvoteDisabled}
								onClick={() => handleFeedback({ voteType: Vote.UPVOTE })}
								className='z-10 job-listing-feedback-button rounded-full hidden md:flex bg-gray-100 active:bg-gray-100 disabled:bg-gray-100 disabled:opacity-25 focus:outline-none focus:shadow-outline-gray shadow-outline-gray rounded-full flex justify-end p-1'
								type='button'
							>
								<ThumbUpOutline className={'job-listing-feedback-button w-5 h-5 text-gray-500'} />
							</button>
							{/* Toggle Button */}
							{!is_promo ? (<button
								onClick={e => toggleDetailSection(e)}
								className='z-10 job-listing-details-toggle-button rounded-full bg-gray-100 active:bg-gray-100 focus:outline-none focus:shadow-outline-gray shadow-outline-gray rounded-full flex justify-end p-1'
								type='button'
							>
								<ChevronLeft
									className={`w-5 h-5 text-gray-500 ${toggleDetails ? '-rotate-90 transform' : ''}`} />
							</button>) : null}
						</div>
					</div>) : (
						<div className='w-1/4 lg:block self-center'>
							<div className='flex justify-end items-center space-x-4'>
								<div className={'flex justify-end'}>
									<div className={'flex justify-end inline-block ml-1 text-xs text-gray-400'}>
										<Tag color={'indigo'}>
											{'PROMO'}
										</Tag>
									</div>
									<div className={'flex inline-block whitespace-no-wrap'}>
										<a onClick={handlePromoClick} href={apply_url} target={'_blank'}>
											<ExternalLink className={'inline-block text-indigo-600 h-7 w-7'} />
										</a>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{toggleDetails && !is_promo ?
				<ListingDetails notificationCallback={callback} closeDetailsCallback={toggleDetailSection}
								listing={listing} /> : null}
		</li>
	);
};

export default Listing;
