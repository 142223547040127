import React, { Fragment } from 'react';

const Tag = ({ children, color = 'green', randomColor = false }: { children?; color?: string; randomColor?: boolean }) => {
	const randomColorGenerator = () => {
		const colors = ['green', 'red', 'green', 'blue', 'orange', 'teal', 'yellow', 'purple', 'pink'];
		return colors[Math.floor(Math.random() * 9)];
	};
	return (
		<Fragment>
			<div
				className={`${
					randomColor ? `bg-${randomColorGenerator()}-100` : `bg-${color}-100`
				} inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 text-gray-800 m-1`}
			>
				{children}
			</div>
		</Fragment>
	);
};

export default Tag;
