import React, { Fragment, useContext, useState } from 'react';
import NotificationContext from '../context/NotificationContext';
import { BEARER_TOKEN, Notifications } from "../constants";

const SubscribeInput = () => {
	const { state, dispatch } = useContext(NotificationContext);
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const handleChange = e => {
		setEmail(e.target.value);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		const API_KEY = 'EwoGCHKVKudIL425po2BtQ';
		const body = {
			api_key: API_KEY,
			email: email
		};
		const res = await fetch('https://api.convertkit.com/v3/forms/1915326/subscribe', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8'
			},
			body: JSON.stringify(body)
		});
		const data = await res.json();
		setLoading(true);
		dispatch({ type: Notifications.DISPLAY, payload: { title: `Successfully Subscribed`, message: `You have successfully been subscribed to faang.io`, success: true } });
		setTimeout(() => {
			dispatch({ type: Notifications.HIDE });
			setLoading(false);
		}, 3000);
	};

	return (
		<form onSubmit={e => handleSubmit(e)} className='sm:flex' aria-labelledby='newsletter-headline'>
			<input
				aria-label='Email address'
				type='email'
				required
				value={email}
				onChange={e => handleChange(e)}
				className='appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out'
				placeholder='Enter your email'
			/>
			<div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0'>
				<button
					type={'submit'}
					className='w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-400 hover:opacity-75 focus:outline-none focus:bg-green-400 transition duration-150 ease-in-out'
				>
					{loading ? (
						<svg className={`animate-spin h-6 w-6 transition duration-150 ease-in-out`} viewBox='0 0 24 24'>
							<circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
							<path
								className='opacity-75'
								fill='currentColor'
								d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
							/>
						</svg>
					) : null}
					{!loading ? <div className={`inline-block transition duration-150 ease-in-out`}>Notify me</div> : null}
				</button>
			</div>
		</form>
	);
};

export default SubscribeInput;
