import React, { Fragment } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { useGeoLocation } from '../hooks/useGeoLocation';

const AppLayout: ({ children }: { children: any }) => any = ({ children }) => {
	// const { latitude, longitude, error } = useGeoLocation();
	return (
		<Fragment>
			<Header />
			<div className='mx-auto'>{children}</div>
			<Footer />
		</Fragment>
	);
};

export default AppLayout;
