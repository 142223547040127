import React, { Fragment, useMemo } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PoweredByStripeLogo from '../assets/img/stripe-black.svg';

const useOptions = () => {
	return useMemo(
		() => ({
			style: {
				base: {
					color: '#424770',
					// letterSpacing: '0.025em',
					fontFamily: 'inherit',
					'::placeholder': {
						color: '#aab7c4'
					}
				},
				invalid: {
					color: '#9e2146'
				}
			}
		}),
		[]
	);
};

const PaymentForm = ({ onChange, value }) => {
	const options = useOptions();

	return (
		<div className={'md:grid-cols-4'}>
			<div className='col-span-6 sm:col-span-3'>
				<input
					onChange={e => onChange(e)}
					placeholder={'Billing Email*'}
					name={'billing_email'}
					required
					value={value}
					id='company'
					className='mb-4 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
				/>
			</div>
			<CardElement
				className={
					'md:col-span-3 mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
				}
				options={options}
			/>
			<div className={'mt-5 md:col-span-1 flex justify-end'}>
				<img className={'h-8 w-auto opacity-50'} src={PoweredByStripeLogo} alt={'Payments Powered by Stripe'} />
			</div>
		</div>
	);
};

export default PaymentForm;
