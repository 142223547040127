import React, { Fragment } from 'react';
import { SpeakerphoneOutline } from 'heroicons-react';
import { Link } from 'react-router-dom';

const Banner = ({ color = 'green', shade = '600', mobileText, webText, buttonText, fixed = false }) => {
	return (
		<Fragment>
			<div className={`${fixed ? 'fixed' : null} bottom-0 inset-x-0 pb-2 sm:pb-5`}>
				<div className='max-w-screen-xl mx-auto'>
					<div className={`p-2 rounded-lg bg-${color}-${shade} shadow-lg sm:p-3`}>
						<div className='flex items-center justify-between flex-wrap'>
							<div className='w-0 flex-1 flex items-center'>
								<span className={`flex p-2 rounded-lg bg-${color}-800`}>
									<SpeakerphoneOutline className={'h-6 w-6 text-white'} />
								</span>
								<p className='ml-3 font-medium text-white truncate'>
									<span className='sm:hidden'>{mobileText}</span>
									<span className='hidden md:inline'>{webText}</span>
								</p>
							</div>
							<div>
								<div className='rounded-md shadow-sm'>
									<Link to={'/feedback'} className={`flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-${color}-600 bg-white hover:text-${color}-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150`}>
										{buttonText}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Banner;
