import React, { Fragment } from 'react';
import Facebook from "../assets/img/facebook.svg"
import Amazon from "../assets/img/amazon.svg"
import Apple from "../assets/img/apple.svg"
import Netflix from "../assets/img/netflix.svg"
import Google from "../assets/img/google.svg"
import { Link } from "react-router-dom";

const LogoCloud = () => {
    return (
        <Fragment>
            <div className="bg-gray-100 border-t border-gray-300">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                        <div className="z-10 col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                            <Link key={0} to={`/facebook/listings`}>
                                <img className="h-8" src={Facebook} alt="Facebook"/>
                            </Link>
                        </div>
                        <div className="z-10 col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                            <Link key={1} to={`/amazon/listings`}>
                                <img className="h-8" src={Amazon} alt="Amazon"/>
                            </Link>
                        </div>
                        <div className="z-10 col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                            <Link key={2} to={`/apple/listings`}>
                                <img className="h-8" src={Apple} alt="Apple"/>
                            </Link>
                        </div>
                        <div className="z-10 col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                            <Link key={3} to={`/netflix/listings`}>
                                <img className="h-8" src={Netflix} alt="Netflix"/>
                            </Link>
                        </div>
                        <div className="z-10 col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                            <Link key={4} to={`/google/listings`}>
                                <img className="h-8" src={Google} alt="Google"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default LogoCloud;
