import { Query } from "../constants";

const queryReducer = (state: any, action: any) => {
    switch (action.type) {
        case Query.QUERY:
            return {
                ...state,
                query: action.payload
            }
        case Query.DISTANCE:
            return {
                ...state,
                distance: action.payload
            }
        default:
            return state
    }
}

export default queryReducer;
