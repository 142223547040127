import React, { Fragment } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PromoDetails from '../../components/PromoDetails';
import { STRIPE_PUBLISHABLE_KEY } from '../../constants';

const PromoPost = () => {
	const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
	return (
		<Fragment>
			<Elements stripe={stripePromise}>
				<PromoDetails />
			</Elements>
		</Fragment>
	);
};

export default PromoPost;
