// import jwt_decode from 'jwt-decode';

const authReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'USER_LOGIN':
			return {
				...state,
				authenticated: true,
				user: action.payload
			};
		case 'USER_LOGOUT':
			return {
				...state,
				authenticated: false,
				user: null
			};
		default:
			return state;
	}
};

export default authReducer;
