import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
import Toast from './Toast';
import PromoContext from '../context/PromoContext';
import NotificationContext from '../context/NotificationContext';
import DefaultCompanyLogo from '../assets/img/company.jpg';
import { BEARER_TOKEN, Notifications } from '../constants';
import Listing from './Listing';
import moment from 'moment-timezone';

interface adPost {
	company: string;
	apply_url: string;
	logo_url: string;
	title: string;
	city: string;
	salary: string;
	description: string;
}

const PromoDetails = () => {
	const { state: promo, dispatch } = useContext(PromoContext);
	const { state: notification, dispatch: notificationDispatch } = useContext(NotificationContext);
	const [promotion, setPromotion] = useState<any>(promo.post);
	const [file, setFile] = useState<FileList | null>(null);
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [promoPrice, setPromoPrice] = useState(19);
	const stripe = useStripe();
	const elements = useElements();

	const DEFAULT_PROMO_DAYS = 7;
	const PROMO_POSTED_DATE = new Date(moment().utc().startOf('day').add(DEFAULT_PROMO_DAYS, 'days').format()).toISOString();
	promotion.posted_date = PROMO_POSTED_DATE;
	const RANDOM_VIEWS = Math.floor(Math.random() * 100);
	const RANDOM_CLICKS = Math.floor(Math.random() * Math.floor(RANDOM_VIEWS));

	const handleChange = async e => {
		const updatedPromotion = { ...promotion };
		if (e.target.files) {
			setFile(e.target.files);
			updatedPromotion['file'] = e.target.files;
		}
		updatedPromotion[e.target.name] = e.target.value;
		setPromotion(updatedPromotion);
		dispatch({ type: 'PROMO_POST', payload: updatedPromotion });
	};

	const submitFile = async () => {
		try {
			if (!file) {
				throw new Error('Select a file first!');
			}
			const formData = new FormData();
			formData.append('file', file[0] ? file[0] : null);
			const res = await fetch(`/api/upload`, {
				method: 'POST',
				body: formData
			});
			const data = await res.json();
		} catch (error) {
			console.log('FILE UPLOAD ERROR:', error);
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();
		if (promotion['file']) await submitFile();
		if (!validated) {
			notificationDispatch({
				type: Notifications.DISPLAY,
				payload: {
					title: `Please fill out required fields`,
					message: `You must fill out the required fields in order to proceed.`,
					success: false
				}
			});
			setTimeout(() => {
				notificationDispatch({ type: Notifications.HIDE });
			}, 7500);
		}
		setLoading(true);
		const payment = await handleStripePayment(e);
		if (!payment) {
			notificationDispatch({
				type: Notifications.DISPLAY,
				payload: {
					title: `Payment Failed`,
					message: `We are unable to process your payment. Please try again.`,
					success: false
				}
			});
			setTimeout(() => {
				notificationDispatch({ type: Notifications.HIDE });
			}, 7500);
		} else {
			const res = await fetch('/api/listings/new', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(promotion)
			});
			const data = await res.json();

			notificationDispatch({
				type: Notifications.DISPLAY,
				payload: {
					title: `Payment Successful`,
					message: `We have received your payment. Your job post is now live!`,
					success: true
				}
			});
			if (typeof window !== 'undefined') {
				window.location.href = '/';
			}
			setTimeout(() => {
				notificationDispatch({ type: Notifications.HIDE });
			}, 5000);
		}
		setLoading(false);
	};

	const handleDefaultImage = e => {
		e.target.src = DefaultCompanyLogo;
	};

	const validateFormFields = () => {
		if (!promotion.company) {
			return setValidated(false);
		}

		if (!promotion.title) {
			return setValidated(false);
		}
		dispatch({ type: 'PROMO_POST_VALID', payload: true });
		return setValidated(true);
	};

	const stripeTokenHandler = async token => {
		const paymentData = {
			token: token.id,
			amount: promoPrice * 100 // dollars -> cents conversion for stripe
		};
		const res = await fetch('/api/payments/new', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(paymentData)
		});
		const data = await res.json();
		return !!data.charge;
	};

	const handleStripePayment = async e => {
		e.preventDefault();
		if (!stripe || !elements) return;

		const cardElement = elements.getElement(CardElement);
		const res = await stripe.createToken(cardElement);
		if (res.error) {
			console.log('Payment Processing Error:', res.error.message);
			return false;
		} else {
			return await stripeTokenHandler(res.token);
		}
	};

	useEffect(() => {
		validateFormFields();
	}, [promotion]);

	return (
		<Fragment>
			<form onSubmit={e => handleSubmit(e)} encType='multipart/form-data'>
				<div className='bg-gray-100'>
					<div className='w-10/12 md:w-8/12 m-auto lg:min-w-0 lg:flex-1 py-15'>
						<div className='mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
							<div className='md:grid md:grid-cols-3 md:gap-6'>
								<div className='md:col-span-3'>
									<h3 className='text-lg font-medium leading-6 text-gray-900'>Promo Information</h3>
									<p className='mt-1 text-sm leading-5 text-gray-500'>This information will be
										displayed publicly.</p>
								</div>
								<div className='mt-5 md:mt-0 md:col-span-3'>
									<div className='grid grid-cols-3 gap-6'>
										<div className='col-span-6 sm:col-span-3'>
											<label htmlFor='company'
												   className='block text-sm font-medium leading-5 text-gray-700'>
												Partner Name*
											</label>
											<input
												onChange={e => handleChange(e)}
												placeholder={'Partner Name'}
												name={'company'}
												required
												value={promotion.company}
												id='company'
												className='mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
											/>
										</div>
										<div className='col-span-6 sm:col-span-3'>
											<label htmlFor='apply_url'
												   className='block text-sm font-medium leading-5 text-gray-700'>
												Partner URL (optional)
											</label>
											<input
												id='apply_url'
												onChange={e => handleChange(e)}
												name={'apply_url'}
												value={promotion.apply_url}
												className='mt-1 form-input flex-1 block w-full rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5'
												placeholder='www.example.com'
											/>
										</div>
										<div className='mt-2 sm:mt-0 col-span-6 sm:col-span-3'>
											<label htmlFor='logo_url'
												   className='block text-sm font-medium leading-5 text-gray-700'>
												Partner Logo URL (optional)
											</label>
											<span className={'text-xs italic text-gray-400'}>Recommended size 100px x 100px</span>
											<input
												onChange={e => handleChange(e)}
												placeholder={'Logo URL'}
												name={'logo_url'}
												value={promotion.logo_url}
												id='logo_url'
												className='mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
											/>
											<div className='mt-2 sm:mt-0 col-span-6 sm:col-span-3'>
												<label htmlFor='logo_preview'
													   className='mt-4 block text-sm font-medium leading-5 text-gray-700'>
													Logo Preview
												</label>
												<span id={'logo-preview'}
													  className='h-5 w-5 rounded-full overflow-hidden bg-gray-100'>
													<img
														style={{ maxWidth: 100, maxHeight: 100 }}
														onError={e => handleDefaultImage(e)}
														alt={promotion.company}
														src={promotion.logo_url}
														className='h-auto w-auto block text-gray-300'
													/>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
							<div className='md:grid md:grid-cols-3 md:gap-6'>
								<div className='md:col-span-3'>
									<h3 className='text-lg font-medium leading-6 text-gray-900'>Promo Details</h3>
									<p className='mt-1 text-sm leading-5 text-gray-500'>Enter a short description about
										your promotion.</p>
								</div>
								<div className='mt-5 md:mt-0 md:col-span-3'>
									<div className='grid grid-cols-6 gap-6'>
										<div className='col-span-6 sm:col-span-6'>
											<label htmlFor='title'
												   className='block text-sm font-medium leading-5 text-gray-700'>
												Promo Description*
											</label>
											<input
												onChange={e => handleChange(e)}
												required
												placeholder={'CodeSnippet Algorithm Tutorials & Walkthroughs'}
												id='title'
												name={'title'}
												value={promotion.title}
												className='mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
											/>
										</div>

										{/*<div className='col-span-6 sm:col-span-6'>*/}
										{/*	<label htmlFor='description' className='mb-1 block text-sm font-medium leading-5 text-gray-700'>*/}
										{/*		Promo Description* (Markdown Supported)*/}
										{/*	</label>*/}
										{/*	<MarkdownEditor handleChange={e => handleChange(e)} name={'description'} text={promotion.description} />*/}
										{/*</div>*/}
									</div>
								</div>
							</div>
						</div>

						<div className='mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
							<div className='md:col-span-3'>
								<h3 className='text-lg font-medium leading-6 text-gray-900'>Promo Preview</h3>
								<p className='my-3 text-sm leading-5 text-gray-500'>Live preview of what your promotion
									would look like</p>
							</div>
							<ul className=''>
								<Listing
									company={promotion.company || 'CodeSnippet.io'}
									logo_url={promotion.logo_url || 'https://codesnippet.io/favicon.ico'}
									title={promotion.title || 'CodeSnippet Algorithm Tutorials & Walkthroughs'}
									city={promotion.apply_url.split('?')[0] || 'https://codesnippet.io'}
									posted_date={PROMO_POSTED_DATE}
									apply_url={promotion.apply_url || 'https://codesnippet.io'}
									billing_email={promotion.billing_email}
									is_promo={true}
									is_enabled={true}
								/>
							</ul>
						</div>

						<div className='mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
							<div className='md:grid md:grid-cols-6 md:gap-1'>
								<div className='md:col-span-3'>
									<h3 className='text-lg font-medium leading-6 text-gray-900'>Payment Information</h3>
									<p className='mt-1 text-sm leading-5 text-gray-500'>Secure payments handled by
										Stripe.</p>
								</div>
								<div className='md:col-span-3'>
									<PaymentForm value={promotion.billing_email} onChange={handleChange} />
								</div>
							</div>
						</div>

						<div>
							<div className={'mt-5 flex justify-end'}>
								<div className='w-full md:w-2/5 block'>
									<div className='rounded-md shadow'>
										<button
											disabled={!validated || loading}
											type={'submit'}
											className={`${
												(!validated || loading) ? 'bg-green-300' : 'bg-green-500'
											} cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10`}
										>
											{loading ? (
												<svg className={`animate-spin h-6 w-6 transition duration-150 ease-in-out mr-4`} viewBox='0 0 24 24'>
													<circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
													<path
														className='opacity-75'
														fill='currentColor'
														d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
													/>
												</svg>
											) : null}
											{ !loading ? `Post Promotion for $${promoPrice}` : 'Processing Payment...'}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			{<Toast display={notification.display} title={notification.title} message={notification.message}
					success={notification.success} />}
		</Fragment>
	);
};

export default PromoDetails;
