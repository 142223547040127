import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Companies, ListingSettings, ListingTypes, Notifications, Query, Vote } from "../constants";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router';
import Listing from './Listing';
import Skeleton from 'react-loading-skeleton';
import ThreeDotsLoader from './ThreeDotsLoader';
import Toast from './Toast';
import Banner from './Banner';
import ListingSubscribe from './ListingSubscribe';
import NotificationContext from '../context/NotificationContext';
import QueryContext from '../context/QueryContext';
import CompanySection from "./CompanySection";
import { ChevronDoubleDown } from "heroicons-react";
import InfoBar from "./InfoBar";
import { BEARER_TOKEN} from "../constants";

const ListingSection = ({ listingType = ListingTypes.DEFAULT }) => {
    const { state: notification, dispatch: notificationDispatch } = useContext(NotificationContext);
    const { state, dispatch } = useContext(QueryContext);
    const { query: location, distance } = state;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryParams = useQuery();
    const locationFromQueryParams = queryParams.get('id');
    const distanceFromQueryParams = queryParams.get('distance') || ListingSettings.DEFAULT_DISTANCE
    const { company }: any = useParams();
    const [page, setPage] = useState(0);
    const [listings, setListings] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [loadingInitialListings, setLoadingInitialListings] = useState(true);
    const [endOfListings, setEndOfListings] = useState(false);
    const [queryHasResults, setQueryHasResults] = useState(true);
    const limit = ListingSettings.LIMIT;

    const previousCompanyRef = useRef();
    const previousLocationRef = useRef();
    const previousDistanceRef = useRef();
    useEffect(() => {
        previousCompanyRef.current = company;
        previousLocationRef.current = location;
        previousDistanceRef.current = distance;
    })
    const previousCompany = previousCompanyRef.current;
    const previousLocation = previousLocationRef.current;
    const previousDistance = previousDistanceRef.current;
    const companyLocationOrDistanceChanged = (previousCompany !== company) || (previousLocation !== location) || (previousDistance !== distance);

    const resetStateOnEntityChange = () => {
        setPage(0);
        // setLoadingInitialListings(true);
        setEndOfListings(false);
        // setQueryHasResults(false);
    }

    const apiUrl = (listingType?) => {
        const requestedPage = companyLocationOrDistanceChanged ? 0 : page
        switch (listingType) {
            case ListingTypes.LOCATION:
                return `/api/listings/location?id=${encodeURI(location)}&distance=${distance}&page=${requestedPage}&limit=${limit}`;
            case ListingTypes.COMPANY:
                return `/api/listings/company/${company}?page=${requestedPage}&limit=${limit}`;
            default:
                return `/api/listings/all?page=${requestedPage}&limit=${limit}`
        }
    }

    const fetchListings = async (listingType) => {
        // Determine if the dependent entity of the specified listingType exists before fetchingData
        if (!fetchingData && (listingType === ListingTypes.LOCATION && location || listingType == ListingTypes.COMPANY && company || listingType == ListingTypes.DEFAULT)) {
            setFetchingData(true);
            const res = await fetch(apiUrl(listingType));
            const data = await res.json();
            const newListingsPlusExisting = companyLocationOrDistanceChanged ? [...data.listings] : [...listings, ...data.listings];
            const { total: totalListings } = data;
            setListings(newListingsPlusExisting);
            setFetchingData(false);
            setPage(page + 1);
            if (!totalListings) setQueryHasResults(false);
            if (totalListings % (page * limit) === totalListings) setEndOfListings(true);
        }
    };

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (!endOfListings) {
            setTimeout(() => {
                fetchListings(listingType).catch(err => err);
            }, 750);
        }
    };

    const handleClickForMoreListings = () => {
        if (!endOfListings) {
            setTimeout(() => {
                fetchListings(listingType).catch(err => err);
            }, 750);
        }
    }

    useEffect(() => {
        dispatch({ type: Query.QUERY, payload: locationFromQueryParams })
        dispatch({ type: Query.DISTANCE, payload: distanceFromQueryParams })
    }, [locationFromQueryParams, distanceFromQueryParams])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        resetStateOnEntityChange();
        fetchListings(listingType).catch(err => err);
    }, [companyLocationOrDistanceChanged]);

    const handleNotification = (type: any) => {
        notificationDispatch({
            type: Notifications.DISPLAY,
            payload: {
                title: `Job Listing ${type === Vote.UPVOTE ? Vote.UPVOTED : Vote.DOWNVOTED}`,
                message: `Thank you for your feedback!`,
                success: type === Vote.UPVOTE
            }
        });
        setTimeout(() => {
            notificationDispatch({ type: Notifications.HIDE });
        }, 3000);
    };

    const renderListings = () => {
        return listings.map((listing, i) => {
            return listing.is_enabled ? (
                <Listing
                    key={i}
                    _id={listing._id}
                    company={listing.company}
                    logo_url={listing.logo_url}
                    company_tag={listing.company_tag}
                    title={listing.title}
                    description={listing.description}
                    salary={listing.salary}
                    city={listing.city}
                    apply_url={listing.apply_url}
                    tags={listing.tags}
                    views={listing.views}
                    clicks={listing.clicks}
                    posted_date={listing.posted_date}
                    billing_email={listing.billing_email}
                    is_promo={listing.is_promo}
                    is_enabled={listing.is_enabled}
                    callback={handleNotification}
                />
            ) : null;
        });
    };

    const renderSectionHeadline = (listingType) => {
        const faangCompany = () => {
            switch (company) {
                case Companies.FACEBOOK.toLowerCase():
                    return Companies.FACEBOOK
                case Companies.AMAZON.toLowerCase():
                    return Companies.AMAZON
                case Companies.APPLE.toLowerCase():
                    return Companies.APPLE
                case Companies.NETFLIX.toLowerCase():
                    return Companies.NETFLIX
                default:
                    return Companies.GOOGLE
            }
        }
        if (!queryHasResults) {
            return <InfoBar color={'red'}
                            content={`No FAANG engineering listings available for your query. Please leave us feedback so we can better fulfill your request.`}/>
        }
        switch (listingType) {
            case ListingTypes.LOCATION:
                return <h1 className='flex-1 text-center text-lg leading-7 font-bold'>FAANG Engineering Jobs
                    near {location}</h1>
            case ListingTypes.COMPANY:
                return <h1 className='flex-1 text-center text-lg leading-7 font-bold'>FAANG Engineering Jobs
                    at {faangCompany()}</h1>
            default:
                return <h1 className='flex-1 text-center text-lg leading-7 font-bold'>FAANG Engineering Jobs</h1>
        }
    }

    return (
        <Fragment>
            <div className='w-10/12 md:w-8/12 m-auto bg-white lg:min-w-0 lg:flex-1 my-5'>
                <div className='pl-4 pr-6 pt-4 pb-4 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6'>
                    <div className='flex items-center'>
                        {renderSectionHeadline(listingType)}
                    </div>
                </div>
                {fetchingData ? (
                    <Fragment>
                        <li className='list-none mb-5 shadow border border-gray-200 rounded-md relative pl-3 pr-3 pt-3 pb-3 hover:bg-gray-50 sm:pt-3 sm:pb-3 sm:pl-3 lg:pl-3 xl:pl-3'>
                            <Skeleton count={2}/>
                        </li>
                        <li className='list-none mb-5 shadow border border-gray-200 rounded-md relative pl-3 pr-3 pt-3 pb-3 hover:bg-gray-50 sm:pt-3 sm:pb-3 sm:pl-3 lg:pl-3 xl:pl-3'>
                            <Skeleton count={2}/>
                        </li>
                        <li className='list-none mb-5 shadow border border-gray-200 rounded-md relative pl-3 pr-3 pt-3 pb-3 hover:bg-gray-50 sm:pt-3 sm:pb-3 sm:pl-3 lg:pl-3 xl:pl-3'>
                            <Skeleton count={2}/>
                        </li>
                        <li className='list-none mb-5 shadow border border-gray-200 rounded-md relative pl-3 pr-3 pt-3 pb-3 hover:bg-gray-50 sm:pt-3 sm:pb-3 sm:pl-3 lg:pl-3 xl:pl-3'>
                            <Skeleton count={2}/>
                        </li>
                        <li className='list-none mb-5 shadow border border-gray-200 rounded-md relative pl-3 pr-3 pt-3 pb-3 hover:bg-gray-50 sm:pt-3 sm:pb-3 sm:pl-3 lg:pl-3 xl:pl-3'>
                            <Skeleton count={2}/>
                        </li>
                    </Fragment>
                ) : null}
                <ul className='my-5 relative'>
                    {renderListings()}
                    <li>
                        <Banner
                            color={'green'}
                            shade={'400'}
                            webText={'Thoughts on our FAANG engineering job listings?'}
                            mobileText={'Any Feedback?'}
                            buttonText={'Reach Out'}
                        />
                    </li>
                </ul>
                {!endOfListings ? (
                    <Fragment>
                        {!fetchingData ? (<div className={'flex items-center flex-col'}>
                                <a className={'flex items-center flex-col cursor-pointer'} onClick={() => handleClickForMoreListings()}>
                                    <p className="text-center text-sm text-gray-300">Scroll or click for more
                                        listings</p>
                                    <ChevronDoubleDown className={'h-5 w-5 text-center text-gray-300'}/>
                                </a>
                            </div>
                        ) : null}
                        <div className={`${!fetchingData ? 'invisible' : 'visible'} flex justify-center`}>
                            <ThreeDotsLoader/>
                        </div>
                    </Fragment>
                ) : null}
                <ListingSubscribe/>
            </div>
            {
                <Toast
                    display={notification.display}
                    title={notification.title}
                    message={notification.message}
                    success={notification.success}
                />
            }
        </Fragment>
    );
};

export default ListingSection;
