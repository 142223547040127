export enum ListingTypes {
	LOCATION = 'LOCATION',
	COMPANY = 'COMPANY',
	DEFAULT = 'DEFAULT'
}

export enum ListingSettings {
	LIMIT = 25,
	DEFAULT_DISTANCE = 20
}
