import React, { Fragment, useState } from 'react';

const DashboardHome = () => {
	return (
		<div className='py-4'>
			<div>
				<div className='max-w-7xl my-4'>
					<h1 className='text-2xl font-semibold text-gray-900'>{`Submissions`}</h1>
				</div>
				<div className='max-w-7xl my-4'>
					<h1 className='mt-5 text-2xl font-semibold text-gray-900'>{`Latest Submissions`}</h1>
				</div>
			</div>
		</div>
	);
};

export default DashboardHome;
