import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../assets/img/logo.svg';
import AuthContext from '../context/AuthContext';
// import Avatar from '../components/Avatar';
// import SidebarMenu from '../components/SidebarMenu';
// import Breadcrumbs from "../components/common/Breadcrumbs";

const DashboardLayout: ({ children }: { children: any }) => any = ({ children }) => {
	const { state: auth } = useContext(AuthContext);
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
	const [avatar, setAvatar] = useState('/assets/img/avatar.png');
	const { dispatch } = React.useContext(AuthContext);
	const history = useHistory();
	const dropDownRef = useRef();

	const handleLogout = async () => {
		const res = await fetch('/auth/logout');
		const data = await res.json();
		dispatch({ type: 'USER_LOGOUT' });
		history.push('/');
	};

	const handleToggleDropDown = () => {
		setToggleDropdown(false);
	};

	useEffect(() => {
		const handleClickEvent = (event: any) => {
			if (dropDownRef.current && !event.target.classList.contains('dashboard-dropdown')) {
				setToggleDropdown(false);
			}
		};
		document.addEventListener('mousedown', handleClickEvent);
		return () => {
			document.removeEventListener('mousedown', handleClickEvent);
		};
	}, [dropDownRef]);

	useEffect(() => {
		// auth && auth.userDetails ? setAvatar(auth.userDetails.avatar) : setAvatar('/assets/img/avatar.png');
		setAvatar('/assets/img/avatar.png');
	}, [auth, auth.userDetails]);

	return (
		<div className='h-screen flex overflow-hidden bg-gray-100 relative'>
			<div className={`md:hidden`}>
				<div className={`${toggleMobileMenu ? 'z-40 fixed' : ''} inset-0 flex`}>
					<div className={`${toggleMobileMenu ? 'opacity-100 fixed inset-0' : 'opacity-0'} transition-opacity ease-linear duration-300`}>
						<div className={`${toggleMobileMenu ? 'absolute inset-0 bg-gray-600 opacity-75' : ''}`} />
					</div>
					{toggleMobileMenu ? (
						<Fragment>
							<div
								className={`${
									toggleMobileMenu ? 'translate-x-0' : '-translate-x-full'
								} transition ease-in-out duration-300 transform relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white`}
							>
								<div onClick={() => setToggleMobileMenu(false)} className='absolute top-0 right-0 -mr-14 p-1'>
									<button className='flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600' aria-label='Close sidebar'>
										<svg className='h-6 w-6 text-white' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
											<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
										</svg>
									</button>
								</div>
								<div className='flex-shrink-0 flex items-center px-4'>
									<Link to={'/'}>
										<img className='h-6 w-auto' src={Logo} alt='faang.io Logo' />
									</Link>
								</div>
								<div className='mt-5 flex-1 h-0 overflow-y-auto'>{/*<SidebarMenu/>*/}</div>
							</div>
							<div className='flex-shrink-0 w-14' />
						</Fragment>
					) : null}
				</div>
			</div>
			<div className='hidden md:flex md:flex-shrink-0'>
				<div className='flex flex-col w-64 border-r border-gray-200 pt-2 pb-4 bg-white'>
					<div className='flex items-center flex-shrink-0 px-4'>
						<Link to={'/'}>
							<img className='h-6 w-auto' src={Logo} alt='faang.io Logo' />
						</Link>
					</div>
					<div className='flex-1 h-0 pt-0 pb-4 overflow-y-auto'>{/*<SidebarMenu/>*/}</div>
				</div>
			</div>
			<div className='flex flex-col w-0 flex-1 overflow-hidden'>
				<div className='relative flex-shrink-0 flex h-16 bg-white shadow'>
					<button
						onClick={() => setToggleMobileMenu(!toggleMobileMenu)}
						className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden'
						aria-label='Open sidebar'
					>
						<svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h7' />
						</svg>
					</button>
					<div className='flex-1 px-4 flex justify-between'>
						<div className='flex-1 flex'>
							<div className='w-full flex md:ml-0'>
								<label htmlFor='search_field' className='sr-only'>
									Search
								</label>
								<div className='relative w-full text-gray-400 focus-within:text-gray-600'>
									<div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
										<svg className='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
											/>
										</svg>
									</div>
									<input
										id='search_field'
										className='block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm'
										placeholder='Search'
										autoComplete={'off'}
										type='search'
									/>
								</div>
							</div>
						</div>
						<div className={'ml-4 flex items-center md:ml-6'}>
							<Link to={'/dashboard/forms/new'}>
								<button
									type='button'
									className='relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150'
								>
									<svg className='-ml-1 mr-2 h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
										<path fillRule='evenodd' d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z' clipRule='evenodd' />
									</svg>
									<span>Post Job Listing</span>
								</button>
							</Link>
						</div>
						<div className='ml-4 flex items-center md:ml-6'>
							<button
								className='p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500'
								aria-label='Notifications'
							>
								<svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
									/>
								</svg>
							</button>
							<div onClick={() => setToggleDropdown(true)} className='ml-3 relative'>
								<div>
									<button
										className='max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline'
										id='user-menu'
										aria-label='User menu'
										aria-haspopup='true'
									>
										{/*<Avatar avatarUrl={avatar} size={8}/>*/}
									</button>
								</div>
								{toggleDropdown ? (
									<div
										className={`${
											toggleDropdown
												? 'transition ease-in duration-75 transform opacity-100 scale-100'
												: 'transition ease-out duration-100 transform opacity-0 scale-95'
										} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg dashboard-dropdown`}
									>
										<div className='py-1 rounded-md bg-white shadow-xs' role='menu' aria-orientation='vertical' aria-labelledby='user-menu'>
											<Link
												onClick={() => handleToggleDropDown()}
												to={'/dashboard/profile'}
												className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 dashboard-dropdown'
												role='menuitem'
											>
												Profile
											</Link>
											<Link
												onClick={() => handleToggleDropDown()}
												to={'/dashboard/settings'}
												className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 dashboard-dropdown'
												role='menuitem'
											>
												Settings
											</Link>
											<Link
												to={'/login'}
												onClick={() => handleLogout()}
												className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 dashboard-dropdown'
												role='menuitem'
											>
												Logout
											</Link>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>

				<main className='flex-1 overflow-y-auto py-6 focus:outline-none' tabIndex={0}>
					<div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>{/*<Breadcrumbs/>*/}</div>
					<div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>{children}</div>
				</main>
			</div>
		</div>
	);
};

export default DashboardLayout;
