import React, { Fragment } from 'react';
import Hero from '../../components/Hero';
import ListingSection from "../../components/ListingSection";
import { ListingTypes } from "../../constants";

const Company = () => {
	return (
		<Fragment>
			<Hero />
			<ListingSection listingType={ListingTypes.COMPANY} />
		</Fragment>
	);
};

export default Company;
